/* eslint-disable max-lines */
import { Abi, Address, zeroAddress } from 'viem'

import { STAKED_USDE_V2_ABI, USDE_ABI, USDT_ABI } from '../constants/abi'
import { CoinGeckoSymbol, TokenType } from '../types'

import { IS_PROD_ENV } from './is-production'

const USDE_ADDRESS = IS_PROD_ENV
  ? '0x4c9EDD5852cd905f086C759E8383e09bff1E68B3'
  : '0x2dFaF238B8255826A160432126E0BC5db20c33e9'

// stETH serves as mock token in UAT for testing purposes
const STETH_ADDRESS = IS_PROD_ENV
  ? '0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84'
  : '0xe90eC12369FbbCBCDaAa320DA59134281458D16B'

const ENA_ADDRESS = process.env.NEXT_PUBLIC_IS_SENA_TESTING
  ? '0x1CdeF1eE3f3b170c5c5FF7c252a9a017B2E4E3C8'
  : '0x57e114B691Db790C35207b2e685D4A43181e6061'

const sENA_ADDRESS = process.env.NEXT_PUBLIC_IS_SENA_TESTING
  ? '0x661ED445C389315A60e1931BAd0B6Fa22e6D519c'
  : '0x8bE3460A480c80728a8C4D7a5D5303c85ba7B3b9'

export const TOKEN_ADDRESSES: Record<TokenType, Address> = {
  AUSDe: '0x4F5923Fc5FD4a93352581b38B7cD26943012DECF',
  BBUSD: zeroAddress,
  BTC: zeroAddress,
  crvUSD: '0xf939E0A03FB07F59A73314E73794Be0E57ac1b4E',
  DAI: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
  ENA: ENA_ADDRESS,
  ETH: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
  'Ether.fi Liquid': '0x08c6F91e2B681FaF5e17227F2a44C307b3C1364C',
  eUSD: '0x939778D83b46B456224A33Fb59630B11DEC56663',
  FDUSD: '0xc5f0f7b66764F6ec8C8Dff7BA683102295E16409',
  FRAX: '0x853d955aCEf822Db058eb8505911ED77F175b99e',
  GHO: '0x40D16FC0246aD3160Ccc09B8D0D3A2cD28aE6C2f',
  'Locked ENA': zeroAddress,
  mETH: '0xd5F7838F5C461fefF7FE49ea5ebaF7728bB0ADfa',
  mkUSD: '0x4591DBfF62656E7859Afe5e45f6f47D3669fBB28',
  'MOE USDe • mETH': '0x74Db2EB31D4B5a0522d3c2eB6dBd87aAc21745f9',
  'MOE USDe • USDT': '0x7bC5Cd04Dd1211a445444dfda6aE11Af27367a2c',
  'PT ENA': '0x9946c55a34cd105f1e0cf815025eaecff7356487',
  'PT ENA OCT': '0xAfA002De2DADb57B2b04e32aa4F42a69AdEBF2FD',
  'PT rsENA': '0x74fd29b63766e9d05ed9d1181a365522d096ed6f',
  PTUSDeJuly: '0xa0021EF8970104c2d008F38D92f115ad56a9B8e1',
  rsENA: '0xc65433845ecD16688eda196497FA9130d6C47Bd8',
  rsUSDe: '0x82f5104b23FF2FA54C2345F821dAc9369e9E0B26',
  sDAI: '0x83F20F44975D03b1b09e64809B757c47f942BEeA',
  sENA: sENA_ADDRESS,
  SolvBTC: zeroAddress,
  stETH: STETH_ADDRESS,
  sUSDe: '0x9D39A5DE30e57443BfF2A8307A4256c8797A3497',
  'sUSDe • PYUSD': zeroAddress,
  'sUSDe • sDAI': '0x167478921b907422F8E88B43C4Af2B8BEa278d3A',
  'sUSDe • USDB': zeroAddress,
  USDB: '0x4300000000000000000000000000000000000003',
  USDC: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  USDe: USDE_ADDRESS,
  'USDe • crvUSD': '0xF55B0f6F2Da5ffDDb104b58a60F2862745960442',
  'USDe • DAI': '0xF36a4BA50C603204c3FC6d2dA8b78A7b69CBC67d',
  'USDe • FDUSD': '0xF8dB2ACcdEf8e7a26b0E65C3980ADC8ce11671a4',
  'USDe • FRAX': '0x5dc1BF6f1e983C0b21EfB003c105133736fA0743',
  'USDe • GHO': '0x670a72e6D22b0956C0D2573288F82DCc5d6E3a61',
  'USDe • mkUSD': '0x1ab3D612EA7df26117554ddDD379764EBcE1A5Ad',
  'USDe • PYUSD': zeroAddress,
  'USDe • USDB': zeroAddress,
  'USDe • USDC': '0x02950460E2b9529D0E00284A5fA2d7bDF3fA4d72',
  'USDe • USDC Arbitrum': '0x1c34204FCFE5314Dcf53BE2671C02c35DB58B4e3',
  'USDe • USDT': '0x435664008F38B0650fBC1C9fc971D0A3Bc2f1e47',
  'USDe • USDX': '0x096a8865367686290639bc50bf8d85c0110d9fea',
  USDT: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  USDX: '0xb2F30A7C980f052f02563fb518dcc39e6bf38175',
  WBETH: '0xa2E3356610840701BDf5611a53974510Ae27E2e1',
  WETH: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
}

export const TOKEN_SYMBOLS: Record<TokenType, string> = {
  AUSDe: 'AUSDe',
  BBUSD: 'BBUSD',
  BTC: 'BTC',
  crvUSD: 'crvUSD',
  DAI: 'DAI',
  ENA: 'ENA',
  ETH: 'ETH',
  'Ether.fi Liquid': 'liquidUSD',
  eUSD: 'eUSD',
  FDUSD: 'FDUSD',
  FRAX: 'FRAX',
  GHO: 'GHO',
  'Locked ENA': 'Locked ENA',
  mETH: 'mETH',
  mkUSD: 'mkUSD',
  'MOE USDe • mETH': 'MOEUSDemETH',
  'MOE USDe • USDT': 'MOEUSDeUSDT',
  'PT ENA': 'PTENA',
  'PT ENA OCT': 'PTENAOCT',
  'PT rsENA': 'PTrsENA',
  PTUSDeJuly: 'USDe',
  rsENA: 'rsENA',
  rsUSDe: 'rsUSDe',
  sDAI: 'sDAI',
  sENA: 'sENA',
  SolvBTC: 'SolvBTC',
  stETH: 'stETH',
  sUSDe: 'sUSDe',
  'sUSDe • PYUSD': 'sUSDePYUSD',
  'sUSDe • sDAI': 'MtEthena',
  'sUSDe • USDB': 'sUSDeUSDB',
  USDB: 'USDB',
  USDC: 'USDC',
  USDe: 'USDe',
  'USDe • crvUSD': 'USDecrvUSD',
  'USDe • DAI': 'USDeDAI',
  'USDe • FDUSD': 'USDeFDUSD',
  'USDe • FRAX': 'FRAXUSDe',
  'USDe • GHO': 'GHOUSDe',
  'USDe • mkUSD': 'mkUSDUSDe',
  'USDe • PYUSD': 'USDePYUSD',
  'USDe • USDB': 'USDeUSDB',
  'USDe • USDC': 'USDeUSDC',
  'USDe • USDC Arbitrum': 'USDEUSDC',
  'USDe • USDT': 'USDeUSDT',
  'USDe • USDX': 'USDeUSDX',
  USDT: 'USDT',
  USDX: 'USDX',
  WBETH: 'wBETH',
  WETH: 'WETH',
}

export const TOKENS_BY_ADDRESS: Record<Address, TokenType> = Object.entries(TOKEN_ADDRESSES).reduce(
  (a, c) => ({ ...a, [c[1]]: c[0] }),
  {},
)

export const TOKEN_ICONS: Record<TokenType, string | string[]> = {
  AUSDe: 'shared/usde.svg',
  BBUSD: 'bbusd.png',
  BTC: 'btc.webp',
  crvUSD: 'crvUSD.png',
  DAI: 'dai.webp',
  ENA: 'shared/ethena.svg',
  ETH: 'ethereum.svg',
  'Ether.fi Liquid': 'etherfi-liquid.png',
  eUSD: 'shared/eusd.svg',
  FDUSD: 'fdusd.svg',
  FRAX: 'frax.svg',
  GHO: 'gho.svg',
  'Locked ENA': 'locked-ena.svg',
  mETH: 'meth.webp',
  mkUSD: 'mkUSD.png',
  'MOE USDe • mETH': ['shared/usde.svg', 'meth.webp'],
  'MOE USDe • USDT': ['shared/usde.svg', 'usdt.png'],
  'PT ENA': 'shared/ethena.svg',
  'PT ENA OCT': 'shared/ethena.svg',
  'PT rsENA': 'shared/rsENA.svg',
  PTUSDeJuly: 'shared/usde.svg',
  rsENA: 'shared/rsENA.svg',
  rsUSDe: 'shared/rsUSDe.svg',
  sDAI: 'sdai.png',
  sENA: 'shared/sENA.svg',
  SolvBTC: 'solvbtc.png',
  stETH: 'lido.png',
  sUSDe: 'shared/sUSDe.svg',
  'sUSDe • PYUSD': ['shared/sUSDe.svg', 'pyusd.png'],
  'sUSDe • sDAI': ['shared/sUSDe.svg', 'sdai.png'],
  'sUSDe • USDB': ['shared/sUSDe.svg', 'usdb.svg'],
  USDB: 'usdb.svg',
  USDC: 'usdc.png',
  USDe: 'shared/usde.svg',
  'USDe • crvUSD': ['shared/usde.svg', 'crvUSD.png'],
  'USDe • DAI': ['shared/usde.svg', 'dai.webp'],
  'USDe • FDUSD': ['shared/usde.svg', 'fdusd.svg'],
  'USDe • FRAX': ['shared/usde.svg', 'frax.svg'],
  'USDe • GHO': ['shared/usde.svg', 'gho.svg'],
  'USDe • mkUSD': ['shared/usde.svg', 'mkUSD.png'],
  'USDe • PYUSD': ['shared/usde.svg', 'pyusd.png'],
  'USDe • USDB': ['shared/usde.svg', 'usdb.svg'],
  'USDe • USDC': ['shared/usde.svg', 'usdc.png'],
  'USDe • USDC Arbitrum': ['shared/usde.svg', 'usdc.png'],
  'USDe • USDT': ['shared/usde.svg', 'usdt.png'],
  'USDe • USDX': ['shared/usde.svg', 'usdx.png'],
  USDT: 'usdt.png',
  USDX: 'usdx.png',
  WBETH: 'wbeth.png',
  WETH: 'weth.png',
}

export const COIN_GECKO_SYMBOLS_MAP: Record<TokenType, CoinGeckoSymbol | null> = {
  AUSDe: null,
  BBUSD: null,
  BTC: null,
  crvUSD: 'crvusd',
  DAI: 'dai',
  ENA: 'ethena',
  ETH: 'ethereum',
  'Ether.fi Liquid': null,
  eUSD: null,
  FDUSD: 'first-digital-usd',
  FRAX: 'frax',
  GHO: 'gho',
  'Locked ENA': null,
  mETH: 'mantle-staked-ether',
  mkUSD: 'prisma-mkusd',
  'MOE USDe • mETH': null,
  'MOE USDe • USDT': null,
  'PT ENA': null,
  'PT ENA OCT': null,
  'PT rsENA': null,
  PTUSDeJuly: null,
  rsENA: null,
  rsUSDe: null,
  sDAI: 'savings-dai',
  sENA: null,
  SolvBTC: null,
  stETH: 'staked-ether',
  sUSDe: null,
  'sUSDe • PYUSD': null,
  'sUSDe • sDAI': null,
  'sUSDe • USDB': null,
  USDB: null,
  USDC: 'usd-coin',
  USDe: null,
  'USDe • crvUSD': null,
  'USDe • DAI': null,
  'USDe • FDUSD': null,
  'USDe • FRAX': null,
  'USDe • GHO': null,
  'USDe • mkUSD': null,
  'USDe • PYUSD': null,
  'USDe • USDB': null,
  'USDe • USDC': null,
  'USDe • USDC Arbitrum': null,
  'USDe • USDT': null,
  'USDe • USDX': null,
  USDT: 'tether',
  USDX: null,
  WBETH: 'wrapped-beacon-eth',
  WETH: 'weth',
}

export const COIN_GECKO_SYMBOLS: CoinGeckoSymbol[] = Object.values(COIN_GECKO_SYMBOLS_MAP).filter(
  symbol => !!symbol,
) as CoinGeckoSymbol[]

export const TOKEN_DECIMALS: Record<string, number> = {
  'Ether.fi Liquid': 6,
  USDC: 6,
  USDT: 6,
}

export const TOKEN_ABI: Record<string, Abi> = {
  sENA: STAKED_USDE_V2_ABI,
  sUSDe: STAKED_USDE_V2_ABI,
  USDe: USDE_ABI,
  USDT: USDT_ABI,
}

export const METH_YIELD = 3.1
